<template>
  <div class="atualizarProduto">
    <MenuLateral
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
    <topoInterno
      titulo="Proposta Online"
      @OpenMenuLateral="menuOpen = true"
    ></topoInterno>

    <div v-if="carregando == true" class="carregando">
      <!--img width="100" src="https://bboneapp.s3.amazonaws.com/evo-white.png" /-->
      <br />
      <br />
      <br />Carregando.. Aguarde
    </div>

    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <div
            block
            v-b-toggle.accordion-2
            variant="info"
            class="topoForm"
            :style="`color:${temaCor.tituloPrimario}; border-left-color:${temaCor.tituloPrimario}`"
          >
            Dados do Beneficiário
            <span class="when-closed">
              <i class="fa fa-chevron-down" aria-hidden="true"></i
            ></span>
            <span class="when-opened">
              <i class="fa fa-chevron-up" aria-hidden="true"></i
            ></span>
          </div>
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <div class="conteudoInterno text-center mt-3 p-3 text-secondary">
              <b-form
                @submitAssociado.stop.prevent="onSubmitAssociado"
                class="mt-4 text-left"
                data-vv-scope="form-1"
              >
                <div>
                  <b-form-group
                    id="input-group-2"
                    label="Nome Completo"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="formCadastroAssociado.associado"
                      name="Nome Completo"
                      v-validate="{ required: true }"
                      :state="validateState('Nome Completo')"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    label="Tipo de Pessoa"
                    label-for="input-2"
                  >
                    <b-form-select
                      v-model="formCadastroAssociado.tipo"
                      :options="tiposPessoas"
                      name="Tipo de Pessoa"
                      v-validate="{ required: true }"
                      :state="validateState('Tipo de Pessoa')"
                      :class="validateClass('Tipo de Pessoa')"
                      @change="resetFormCpfCnpj()"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group
                    v-if="formCadastroAssociado.tipo == 'Pessoa Física'"
                    id="input-group-2"
                    label="CPF"
                    label-for="input-2"
                  >
                    <the-mask
                      class="form-control"
                      id="CPF"
                      name="CPF"
                      key="CPF"
                      autocomplete="off"
                      aria-autocomplete="none"
                      role="presentation"
                      v-model="cpf"
                      v-validate="{ required: true, min: 14 }"
                      :class="validateClass('CPF')"
                      :state="validateState('CPF')"
                      :masked="true"
                      mask="###.###.###-##"
                      :disabled="!isCpfCanChange()"
                    ></the-mask>
                  </b-form-group>

                  <b-form-group
                    v-if="formCadastroAssociado.tipo == 'Pessoa Jurídica'"
                    id="input-group-2"
                    label="CNPJ"
                    label-for="input-2"
                  >
                    <the-mask
                      class="form-control"
                      id="CNPJ"
                      name="CNPJ"
                      key="CNPJ"
                      autocomplete="off"
                      aria-autocomplete="none"
                      role="presentation"
                      v-model="cpf"
                      v-validate="{ required: true, min: 18 }"
                      :class="validateClass('CNPJ')"
                      :state="validateState('CNPJ')"
                      :masked="true"
                      mask="##.###.###/####-##"
                      :disabled="!isCpfCanChange()"
                    ></the-mask>
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    label="E-mail"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="Email"
                      name="Email"
                      v-model="formCadastroAssociado.email"
                      v-validate="{ email: true }"
                      :state="validateState('Email')"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    v-if="formCadastroAssociado.tipo == 'Pessoa Física'"
                    id="input-group-2"
                    label="Data de Nascimento"
                    label-for="input-2"
                  >
                    <the-mask
                      class="form-control"
                      id="input-2"
                      :masked="true"
                      v-model="formCadastroAssociado.dataNascimento"
                      name="Data de Nascimento"
                      key="DataNascimento"
                      v-validate="{
                        required: true,
                        date_format: 'dd/MM/yyyy',
                      }"
                      mask="##/##/####"
                      :state="validateState('Data de Nascimento')"
                      :class="validateClass('Data de Nascimento')"
                    ></the-mask>
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    label="Celular"
                    label-for="input-2"
                  >
                    <the-mask
                      id="Celular"
                      class="form-control"
                      v-model="formCadastroAssociado.celular"
                      name="Celular"
                      :masked="true"
                      mask="(##) #####-####"
                      v-validate="{ required: true }"
                      :state="validateState('Celular')"
                      :class="validateClass('Celular')"
                    ></the-mask>
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    label="Telefone"
                    label-for="input-2"
                  >
                    <the-mask
                      id="Telefone"
                      class="form-control"
                      v-model="formCadastroAssociado.telefone"
                      v-validate="{ min: 14, max: 14 }"
                      name="Telefone"
                      :masked="true"
                      mask="(##) ####-####"
                      :state="validateState('Telefone')"
                      :class="validateClass('Telefone')"
                    ></the-mask>
                  </b-form-group>
                  <hr />

                  <b-form-group label="Cep">
                    <the-mask
                      type="text"
                      class="form-control"
                      v-model="cep"
                      v-validate="{ required: true, length: 10 }"
                      mask="##.###-###"
                      id="Cep"
                      name="Cep"
                      :masked="true"
                      @keyup.native="keymonitor"
                      :state="validateState('Cep')"
                      :class="validateClass('Cep')"
                    ></the-mask>
                  </b-form-group>
                  <div v-if="encontrouCep == false">
                    <b-form-group
                      id="input-group-2"
                      label="Endereço"
                      label-for="input-2"
                    >
                      <b-form-input
                        id="endereco"
                        v-model="formCadastroAssociado.endereco"
                        name="Endereco"
                        v-validate="{ required: true }"
                        :state="validateState('Endereco')"
                      ></b-form-input>
                    </b-form-group>
                    <b-row>
                      <b-col class="w-1/2">
                        <b-form-group
                          id="input-group-2"
                          label="Número"
                          label-for="input-2"
                        >
                          <b-form-input
                            id="input-2"
                            v-model="formCadastroAssociado.numero"
                            name="Numero"
                            v-validate="{ required: true }"
                            :state="validateState('Numero')"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col class="w-1/2">
                        <b-form-group
                          id="input-group-2"
                          label="Complemento"
                          label-for="input-2"
                        >
                          <b-form-input
                            id="input-2"
                            v-model="formCadastroAssociado.complemento"
                            name="Complemento"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-form-group
                      id="input-group-2"
                      label="Bairro"
                      label-for="input-2"
                    >
                      <b-form-input
                        id="input-2"
                        v-model="formCadastroAssociado.bairro"
                        name="Bairro"
                        v-validate="{ required: true }"
                        :state="validateState('Bairro')"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      id="input-group-2"
                      label="Estado"
                      label-for="input-2"
                    >
                      <b-select
                        :options="estados"
                        name="Estado"
                        id="Estado"
                        v-model="estado"
                        @change="buscaDadosSelectsCidades"
                        v-validate="{ required: true }"
                        :state="validateState('Estado')"
                        :class="validateClass('Estado')"
                      ></b-select>
                    </b-form-group>

                    <b-form-group
                      id="input-group-2"
                      label="Cidade"
                      label-for="input-2"
                    >
                      <b-select
                        :options="cidades"
                        name="Cidade"
                        id="Cidade"
                        v-model="formCadastroAssociado.cidade"
                        v-validate="{ required: true }"
                        :state="validateState('Cidade')"
                        :class="validateClass('Cidade')"
                      ></b-select>
                    </b-form-group>
                  </div>
                  <div v-if="encontrouCep == true">
                    <b-form-group
                      id="input-group-2"
                      label="Endereço"
                      label-for="input-2"
                    >
                      <b-form-input
                        id="input-2"
                        v-model="formCadastroAssociado.endereco"
                        name="Endereco"
                        v-validate="{ required: true }"
                        :state="validateState('Endereco')"
                      ></b-form-input>
                    </b-form-group>
                    <b-row>
                      <b-col class="w-1/2">
                        <b-form-group
                          id="input-group-2"
                          label="Número"
                          label-for="input-2"
                        >
                          <b-form-input
                            id="input-2"
                            v-model="formCadastroAssociado.numero"
                            name="Numero"
                            v-validate="{ required: true }"
                            :state="validateState('Numero')"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col class="w-1/2">
                        <b-form-group
                          id="input-group-2"
                          label="Complemento"
                          label-for="input-2"
                        >
                          <b-form-input
                            id="input-2"
                            v-model="formCadastroAssociado.complemento"
                            name="Complemento"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                  <!--b-modal
                    id="modalCpfDuplicado"
                    v-model="formCadastroAssociado.cpfDuplicado"
                    hide-footer
                    centered
                    title="Atenção!"
                    @hidden="resetFormCpfCnpj"
                  >
                    Identificamos que o CPF/CNPJ: {{ this.cpf }}, já encontra-se
                    cadastrado em nossa base de dados. (Todos os dados dos
                    outros registros como propostas e veículos serão unificados
                    no registro). Caso seja um outro Cadastro, informe um novo
                    CPF ou CNPJ.

                    <b-button
                      class="mt-4"
                      block
                      @click="resetFormCpfCnpj()"
                      type="filled"
                      :style="`background-color:${temaCor.botaoFormularios};`"
                    >
                      Informar outro CPF/CNPJ
                    </b-button>

                    <b-button
                      class="mt-4"
                      block
                      @click.stop.prevent="aceiteUnificarAssociado"
                      style="background-color: #dd830b"
                      type="filled"
                    >
                      Unificar Cadastros
                    </b-button>
                  </b-modal-->
                </div>

                <b-button
                  pill
                  type="button"
                  @click="onSubmitAssociado()"
                  class="btn-block mt-3"
                  :style="`background-color:${temaCor.botaoFormularios};`"
                  >Atualizar Associado</b-button
                >
              </b-form>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <div
          block
          v-b-toggle.accordion-1
          class="topoForm"
          :style="`color:${temaCor.tituloPrimario}; border-left-color:${temaCor.tituloPrimario}`"
        >
          Atualizar Produtos
          <span class="when-closed">
            <i class="fa fa-chevron-down" aria-hidden="true"></i
          ></span>
          <span class="when-opened">
            <i class="fa fa-chevron-up" aria-hidden="true"></i
          ></span>
        </div>
      </b-card-header>
      <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="conteudoInterno text-center mt-1 p-0 text-secondary">
            <b-form @submit.stop.prevent="onSubmit" class="mt-4 text-left">
              <div>
                <b-card
                  :class="`${
                    form.agregado ? 'card-visivel my-2' : 'card-invisivel'
                  }`"
                >
                  <h5 class="mb-4 text-center">Veículo</h5>
                  <b-form-checkbox-group
                    id="checkbox-group-2"
                    v-model="form.produtosSelecionados"
                    name="flavour-2"
                  >
                    <b-list-group>
                      <b-list-group-item
                        v-for="item in produtosObrigatorios"
                        :key="item.id_produto"
                      >
                        <b-form-checkbox
                          class="w-100 mb-3"
                          :value="{
                            id: item.id_produto,
                          }"
                          :disabled="validaSelecionado(item)"
                          @click.native.prevent="selecionaProduto(item)"
                          size="lg"
                        >
                          <b class="mr-2">{{ item.nomeProduto }}</b>

                          <span
                            class="mr-2 valorprod"
                            v-if="item.visivelProposta"
                            :style="item.style"
                            >{{ item.valor | currency }}</span
                          >
                          <span
                            class="valorprod"
                            v-if="
                              item.descontoConcedido > 0 && item.visivelProposta
                            "
                            >{{ item.valorFinal | currency }}</span
                          >
                          <span
                            class="valorprod"
                            v-if="item.ismensal && item.visivelProposta"
                            >/mês</span
                          >
                          <button
                            v-if="item.porcentagemDescontoMaxima"
                            type="button"
                            @click="exibePorcentagemDesconto(item)"
                            class="btn btn-dark ml-2"
                          >
                            %
                          </button>
                        </b-form-checkbox>
                      </b-list-group-item>
                    </b-list-group>
                  </b-form-checkbox-group>

                  <b-form-checkbox-group
                    id="checkbox-group-2"
                    v-model="form.produtosSelecionados"
                    v-if="produtosNaoObrigatorios.length > 0"
                    class="mt-3"
                    name="flavour-2"
                  >
                    <b-list-group>
                      <b-list-group-item>
                        <h5 class="my-2 text-center pt-2 pb-3">Opcionais</h5>
                      </b-list-group-item>
                      <b-list-group-item
                        v-for="item in produtosNaoObrigatorios"
                        :key="item.id_produto"
                      >
                        <b-form-checkbox
                          class="w-100 mb-3"
                          :value="{
                            id: item.id_produto,
                          }"
                          @click.native.prevent="selecionaProduto(item)"
                          size="lg"
                        >
                          <b class="mr-2">{{ item.nomeProduto }}</b>
                          <span
                            class="mr-2 valorprod"
                            v-if="item.visivelProposta"
                            :style="item.style"
                            >{{ item.valor | currency }}</span
                          >
                          <span
                            class="valorprod"
                            v-if="
                              item.descontoConcedido > 0 && item.visivelProposta
                            "
                            >{{ item.valorFinal | currency }}</span
                          >
                          <span
                            class="valorprod"
                            v-if="item.ismensal && item.visivelProposta"
                            >/mês</span
                          >
                          <button
                            v-if="item.porcentagemDescontoMaxima"
                            type="button"
                            @click="exibePorcentagemDesconto(item)"
                            class="btn btn-dark ml-2"
                          >
                            %
                          </button>
                        </b-form-checkbox>
                      </b-list-group-item>
                    </b-list-group>
                  </b-form-checkbox-group>
                  <h6 class="mt-4">
                    Total Mensal:
                    <span class="valorprod">{{
                      somaValorTotalMes(form.produtosSelecionados, [
                        ...produtosObrigatorios,
                        ...produtosNaoObrigatorios,
                      ]) | currency
                    }}</span>
                  </h6>
                  <h6>
                    Total à vista:
                    <span class="valorprod">{{
                      somaValorTotal(form.produtosSelecionados, [
                        ...produtosObrigatorios,
                        ...produtosNaoObrigatorios,
                      ]) | currency
                    }}</span>
                  </h6>
                </b-card>
              </div>

              <!-- produtos agregados -->
              <div v-if="form.agregado" class="mb-5">
                <b-card
                  class="mt-4"
                  v-for="(agregado, index) in form.agregados"
                  :key="`agre${index}`"
                >
                  <h5 class="mb-4 text-center">
                    {{
                      agregado.placa
                        ? agregado.placa + ' - '
                        : '' || agregado.chassi
                        ? agregado.chassi + ' - '
                        : ''
                    }}
                    {{ agregado.descricao }}
                  </h5>

                  <b-form-checkbox-group
                    id="checkbox-group-2"
                    v-model="form.agregados[index].produtosSelecionados"
                    name="flavour-2"
                  >
                    <b-list-group>
                      <b-list-group-item
                        v-for="prodObrigatorio in agregado.produtosObrigatorios"
                        :key="`prodObrigAgre-${prodObrigatorio.id_produto}`"
                      >
                        <b-form-checkbox
                          class="w-100 mb-3"
                          :value="{
                            id: prodObrigatorio.id_produto,
                          }"
                          :disabled="
                            validaSelecionadoAgregado(prodObrigatorio, index)
                          "
                          @click.native.prevent="
                            selecionaProdutoAgregado(prodObrigatorio, index)
                          "
                          size="lg"
                        >
                          <b class="mr-2">{{ prodObrigatorio.nomeProduto }}</b>
                          <span
                            class="mr-2 valorprod"
                            v-if="prodObrigatorio.visivelProposta"
                            :style="prodObrigatorio.style"
                            >{{ prodObrigatorio.valor | currency }}</span
                          >
                          <span
                            class="valorprod"
                            v-if="
                              prodObrigatorio.descontoConcedido > 0 &&
                              prodObrigatorio.visivelProposta
                            "
                            >{{ prodObrigatorio.valorFinal | currency }}</span
                          >
                          <span
                            class="valorprod"
                            v-if="
                              prodObrigatorio.ismensal &&
                              prodObrigatorio.visivelProposta
                            "
                            >/mês</span
                          >
                          <button
                            v-if="prodObrigatorio.porcentagemDescontoMaxima"
                            type="button"
                            class="btn btn-dark ml-2"
                            @click="exibePorcentagemDesconto(prodObrigatorio)"
                          >
                            %
                          </button>
                        </b-form-checkbox>
                      </b-list-group-item>
                    </b-list-group>
                  </b-form-checkbox-group>

                  <b-form-checkbox-group
                    id="checkbox-group-2"
                    v-model="form.agregados[index].produtosSelecionados"
                    name="flavour-2"
                    class="mt-4 mb-4"
                  >
                    <b-list-group
                      v-if="agregado.produtosNaoObrigatorios.length > 0"
                    >
                      <b-list-group-item>
                        <h5 class="my-2 text-center pt-2 pb-3">Opcionais</h5>
                      </b-list-group-item>
                      <b-list-group-item
                        v-for="prodNaoObrigatorio in agregado.produtosNaoObrigatorios"
                        :key="`prodNObrigAgre-${prodNaoObrigatorio.id_produto}`"
                      >
                        <b-form-checkbox
                          class="w-100 mb-3"
                          :value="{
                            id: prodNaoObrigatorio.id_produto,
                          }"
                          @click.native.prevent="
                            selecionaProdutoAgregado(prodNaoObrigatorio, index)
                          "
                          size="lg"
                        >
                          <b class="mr-2">{{
                            prodNaoObrigatorio.nomeProduto
                          }}</b>
                          <span
                            class="mr-2 valorprod"
                            v-if="prodNaoObrigatorio.visivelProposta"
                            :style="prodNaoObrigatorio.style"
                            >{{ prodNaoObrigatorio.valor | currency }}</span
                          >
                          <span
                            class="valorprod"
                            v-if="
                              prodNaoObrigatorio.descontoConcedido > 0 &&
                              prodNaoObrigatorio.visivelProposta
                            "
                            >{{
                              prodNaoObrigatorio.valorFinal | currency
                            }}</span
                          >
                          <span
                            class="valorprod"
                            v-if="
                              prodNaoObrigatorio.ismensal &&
                              prodNaoObrigatorio.visivelProposta
                            "
                            >/mês</span
                          >
                          <button
                            v-if="prodNaoObrigatorio.porcentagemDescontoMaxima"
                            type="button"
                            class="btn btn-dark ml-2"
                            @click="
                              exibePorcentagemDesconto(prodNaoObrigatorio)
                            "
                          >
                            %
                          </button>
                        </b-form-checkbox>
                      </b-list-group-item>
                    </b-list-group>
                  </b-form-checkbox-group>

                  <h6>
                    Total Mensal:
                    <span class="valorprod">{{
                      somaValorTotalMes(
                        form.agregados[index].produtosSelecionados,
                        [
                          ...agregado.produtosObrigatorios,
                          ...agregado.produtosNaoObrigatorios,
                        ],
                      ) | currency
                    }}</span>
                  </h6>
                  <h6>
                    Total à vista:
                    <span class="valorprod">{{
                      somaValorTotal(
                        form.agregados[index].produtosSelecionados,
                        [
                          ...agregado.produtosObrigatorios,
                          ...agregado.produtosNaoObrigatorios,
                        ],
                      ) | currency
                    }}</span>
                  </h6>
                </b-card>
              </div>

              <b-form-group label="Observação Geral" label-for="input-2">
                <b-form-textarea
                  id="input-2"
                  v-model="form.observacao"
                  placeholder=""
                  name="Observação Geral"
                  :state="validateState('Observação Geral')"
                  :class="validateClass('Observação Geral')"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-button
                v-if="produtosCarregados"
                pill
                type="submit"
                class="btn-block mt-3"
                variant="info"
                :style="`background-color:${temaCor.botaoFormularios};`"
                >Atualizar Produtos
              </b-button>
            </b-form>
            <b-modal
              v-model="modalDesconto"
              :active.sync="modalDesconto"
              hide-footer
              centered
              title="Desconto"
            >
              <FormDesconto
                :temaCor="temaCor"
                :dados="produtoToDesconto"
                :modal="modalDesconto"
                :contModal="contFormDesconto"
                @salvarDesconto="salvarDesconto"
              ></FormDesconto>
            </b-modal>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<!-- eslint-disable -->
<script>
import locale from './../locale';
import service from '../services';
import Password from 'vue-password-strength-meter';
import topoInterno from './TopoInterno';
import colors from './../services/colors';
import MenuLateral from './MenuLateral';
import FormDesconto from './FormDesconto.vue';
import cpfCnpj from '../services/cpfCnpj';
import { decryptData } from '../services/decryptData';

export default {
  name: 'DashBoard',
  props: {
    msg: String,
  },
  watch: {
    cep: function (newValue, oldValue) {
      if (this.cep)
        if (this.cep.length == 10) {
          this.buscarCep();
        }
    },
    estado: function (newValue) {
      if (newValue !== null) {
        this.formCadastroAssociado.estado = this.estado.valor;
      }
    },
    cpf() {
      if (this.cpf) {
        if (
          (this.cpf.length == 14 &&
            this.formCadastroAssociado.tipo == 'Pessoa Física') ||
          this.cpf.length == 18
        ) {
          this.formCadastroAssociado.cpf = this.cpf;
          // this.validaCpfUnificacao();
        }
      }
    },
  },
  data() {
    return {
      produtosCarregados: false,
      menuOpen: false,
      modalDesconto: false,
      produtoToDesconto: null,
      encontrouCep: null,
      exibeCidade: false,
      temaCor: {},
      dadosProposta: {},
      produtos: [],
      cep: null,
      produtosObrigatorios: [],
      produtosNaoObrigatorios: [],
      contFormDesconto: 0,
      itens: null,
      estados: [],
      estado: null,
      cidades: [],
      grupos: [],
      cliente: {},
      tiposPessoas: [
        { text: 'Selecione', value: null },
        'Pessoa Física',
        'Pessoa Jurídica',
      ],
      form: {
        idProposta: null,
        idEmpresa: null,
        produtosSelecionados: [],
        idTipoDesconto: '',
        agregado: null,
        agregados: [],
      },
      formCadastroAssociado: {
        associado: null,
        bairro: null,
        celular: null,
        cep: null,
        cpf: null,
        cidade: null,
        complemento: null,
        email: null,
        endereco: null,
        estado: null,
        numero: null,
        telefone: null,
        tipo: null,
        unificaAssociado: false,
        cpfDuplicado: false,
      },
      formAux: {
        idEmpresa: null,
        idGrupo: {},
        valor: 0,
        cidade: '',
        estado: '',
        restricoesSelecionadas: [],
        veiculo: {
          cilindradas: null,
          resultFipe: {},
        },
      },
      score: 0,
      erroConexao: '',
      carregando: false,
      logo: 'https://bboneapp.s3.amazonaws.com/evo-gerencial-color.png',
      value: 80,
      max: 100,
      cpf: null,
      formVerificaCpf: {},
      collapseId: null,
      showCollapse: null,
      formUnificacao: {},
    };
  },
  components: { Password, topoInterno, MenuLateral, FormDesconto },
  methods: {
    salvarDesconto(item) {
      if (item.valorDesconto > 0) {
        item.style = 'text-decoration: line-through;';
      } else item.style = null;

      this.produtoToDesconto = null;
      this.modalDesconto = false;
    },
    exibePorcentagemDesconto(item) {
      this.contFormDesconto = this.contFormDesconto + 1;
      this.produtoToDesconto = item;
      this.modalDesconto = true;

      if (this.modalDesconto == true) {
        this.itens = item;
        this.itens.estado = this.formAux.estado;
        this.itens.tipoProposta = this.formAux.tipoProposta;
        this.itens.idEmpresa = this.formAux.idEmpresa;
      }
    },
    buscaProdutos() {
      return new Promise(async (resolve, reject) => {
        this.carregando = true;
        service
          .post('Cotacao', 'BuscaProdutos', this.formAux)
          .then((res) => {
            this.carregando = false;
            if (res.data.length == 0) {
              this.$bvToast.toast(
                'Nenhum Produto Encontrado para esse veículo',
                {
                  title: 'Atenção!',
                  solid: true,
                  variant: 'danger',
                  toaster: 'b-toaster-top-full',
                  appendToast: false,
                  autoHideDelay: 2500,
                },
              );
              reject('Nenhum Produto Encontrado para esse veículo');
            } else {
              var prodsOk = true;
              this.produtos = res.data;
              this.produtosObrigatorios = [];
              this.produtosNaoObrigatorios = [];
              this.form.produtosSelecionados = [];

              if (this.form.codTipoVeiculo != 3 || this.form.somenteAgregado) {
                this.produtos = res.data;
              }

              if (this.form.codTipoVeiculo == 3 && !this.form.somenteAgregado) {
                this.produtos = res.data.produtosVeiculo;
              }

              this.produtos.forEach((element) => {
                element.valorFinal = element.valor;
                element.descontoConcedido = 0;
                var aux = this.dadosProposta.produtos.find(
                  (x) => x.idProduto == element.id_produto,
                );
                if (aux) {
                  element.valorFinal = aux.valorFinal;
                  element.descontoConcedido = aux.descontoConcedido;
                  if (aux.descontoConcedido > 0) {
                    element.style = 'text-decoration: line-through;';
                  }
                }
                if (element.obrigatorio == true) {
                  this.produtosObrigatorios.push(element);
                } else {
                  this.produtosNaoObrigatorios.push(element);
                }
              });

              this.dadosProposta.produtos.forEach((element) => {
                this.form.produtosSelecionados.push({
                  id: element.idProduto,
                });
              });

              if (prodsOk == false) {
                this.$bvToast.toast('Valores fora de Tabela', {
                  title: 'Atenção!',
                  solid: true,
                  variant: 'danger',
                  toaster: 'b-toaster-top-full',
                  appendToast: false,
                  autoHideDelay: 2500,
                });
                reject('Valores fora de Tabela');
              } else {
                this.produtosCarregados = true;
                resolve();
              }
            }
          })
          .catch((e) => {
            this.carregando = false;
            this.$bvToast.toast(e, {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
            reject(e);
          });
      });
    },
    somaValorTotalMes(arrSelecionados, arrProds) {
      var total = 0;
      arrSelecionados.forEach((element) => {
        var prod = arrProds.find((x) => x.id_produto == element.id);
        if (prod) if (prod.ismensal == true) total = total + prod.valorFinal;
      });
      return total;
    },
    somaValorTotal(arrSelecionados, arrProds) {
      var total = 0;
      arrSelecionados.forEach((element) => {
        var prod = arrProds.find((x) => x.id_produto == element.id);
        if (prod) if (prod.ismensal != true) total = total + prod.valorFinal;
      });
      return total;
    },
    buscaDadosSelectsCidades() {
      this.carregando = true;
      this.cidades = [];
      service
        .getAll('g', {}, `domMunicipio/list/codUf/${this.estado.id}`)
        .then((res) => {
          res.data = decryptData(res.data);
          res.data.forEach((element) => {
            this.cidades.push({
              value: element.nomeCidade,
              text: element.nomeCidade,
            });
          });

          this.carregando = false;
        });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      let arrMsg = this.$validator.errors.all();
      let arrAux = [];

      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });

      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    preencheForm() {
      console.log(this.dadosProposta.dadosCliente);
      this.formAux.idEmpresa = this.dadosProposta.idEmpresa;
      this.form.observacao = this.dadosProposta.observacao;
      this.formAux.observacao = this.dadosProposta.observacao;
      this.formAux.agregado = this.dadosProposta.agregado;
      this.formAux.implemento = this.dadosProposta.implemento;
      this.formAux.caminhao = this.dadosProposta.caminhao;
      this.formAux.somenteAgregado = this.dadosProposta.somenteAgregado;

      this.form.agregado = this.dadosProposta.agregado;
      this.form.implemento = this.dadosProposta.implemento;
      this.form.caminhao = this.dadosProposta.caminhao;
      this.form.somenteAgregado = this.dadosProposta.somenteAgregado;
      this.formAux.idUsuarioConsultor =
        this.dadosProposta.dadosVendedor.idUsuario;

      this.form.idProposta = this.dadosProposta.idProposta;
      this.form.codTipoVeiculo = this.dadosProposta.dadosVeiculo.codTipoVeiculo;
      this.formAux.tipoProposta = this.dadosProposta.tipoProposta;
      this.formAux.idGrupo.id = this.dadosProposta.idGrupo;
      this.formAux.valor = this.dadosProposta.dadosVeiculo.valorCobertura;
      this.formAux.cidade = this.dadosProposta.dadosCliente.cidade;
      this.formAux.estado = this.dadosProposta.dadosCliente.estado;
      this.formAux.restricoesSelecionadas = [];
      this.dadosProposta.restricoes.forEach((element) => {
        this.formAux.restricoesSelecionadas.push(element.idRestricao);
      });
      this.formAux.veiculo = {
        cilindradas: this.dadosProposta.dadosVeiculo.cilindradas,
        resultFipe: JSON.parse(this.dadosProposta.dadosVeiculo.resultadoFipe),
        codTipoVeiculo: this.dadosProposta.dadosVeiculo.codTipoVeiculo,
      };
      if (!this.formAux.cidade || !this.formAux.estado) {
        this.$bvToast.toast(
          'Associado sem dados de Estado e Cidade, atualize os dados e tente novamente',
          {
            title: 'Atenção!',
            solid: true,
            variant: 'danger',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 2500,
          },
        );
      }
      this.formAux.veiculo.resultFipe.tipo = null;

      //formCadastroAssociado para atualizar somente os dados do Beneficiário
      this.formCadastroAssociado.associado = this.cliente.associado;
      this.formCadastroAssociado.bairro = this.cliente.bairro;
      this.formCadastroAssociado.celular = this.cliente.celular;
      this.cep = this.cliente.cep;
      this.formCadastroAssociado.cidade = this.cliente.cidade;
      this.formCadastroAssociado.complemento = this.cliente.complemento;
      this.formCadastroAssociado.email = this.cliente.email;
      this.formCadastroAssociado.endereco = this.cliente.endereco;
      this.formCadastroAssociado.cidade = this.cliente.cidade;
      this.formCadastroAssociado.estado = this.cliente.estado;
      this.formCadastroAssociado.numero = this.cliente.numero;
      this.formCadastroAssociado.telefone = this.cliente.telefone;
      this.formCadastroAssociado.tipo = this.cliente.tipo;
      this.formCadastroAssociado.idProspect = this.cliente.idProspect;
      this.formCadastroAssociado.dataNascimento = this.cliente.dataNascimento;
      this.cpf = this.cliente.cpf;
      this.formCadastroAssociado.dataVencimentoCnh =
        this.cliente.dataVencimentoCnh;

      if (this.dadosProposta.agregado) {
        this.formAux.agregados = this.dadosProposta.agregado;
        this.formAux.agregados = this.dadosProposta.agregados;
        this.dadosProposta.agregados.forEach((agregado) => {
          const { JsonFacilitadorProdutos, produtosSelecionados } = agregado;
          let produtos = JSON.parse(JsonFacilitadorProdutos);
          const idsProdutosSelecionados = JSON.parse(produtosSelecionados);

          const dadosProdutosSelcionados = agregado.produtosAgregado;

          var aux = [];
          agregado.produtosAgregado.forEach((element) => {
            aux.push({
              id: element.idProduto,
            });

            produtos.forEach((x) => {
              if (x.id_produto == element.idProduto) {
                x.valorFinal = element.valorFinal;

                x.descontoConcedido = element.descontoConcedido;
                if (x.descontoConcedido > 0) {
                  x.style = 'text-decoration: line-through;';
                }
              }
            });
          });

          const { produtosObrigatorios, produtosNaoObrigatorios } =
            this.formataProdutos(produtos);
          form - 2;

          const _agregado = {
            ...agregado,
            produtosObrigatorios,
            produtosNaoObrigatorios,
            produtosSelecionados: aux,
            dadosProdutosSelcionados,
            produtos,
          };

          this.form.agregados.push(_agregado);
        });
      }
    },
    onSubmit(evt) {
      this.$validator.validateAll('form-2').then((result) => {
        if (!result) {
          this.popToast();
          return;
        }
        this.form.produtosSelecionadosAux = [];

        this.form.produtosSelecionados.forEach((element) => {
          var aux = this.produtosObrigatorios.find(
            (x) => x.id_produto == element.id,
          );
          if (aux) {
            var desconto = aux.descontoConcedido ? aux.descontoConcedido : 0;

            this.form.produtosSelecionadosAux.push({
              id: aux.id_produto,
              valor: aux.valor,
              valorFinal: aux.valorFinal,
              descontoConcedido: desconto,
              idTipoDesconto: aux.idTipoDesconto,
            });
          }

          var aux2 = this.produtosNaoObrigatorios.find(
            (x) => x.id_produto == element.id,
          );
          if (aux2) {
            var desconto = aux2.descontoConcedido ? aux2.descontoConcedido : 0;

            this.form.produtosSelecionadosAux.push({
              id: aux2.id_produto,
              valor: aux2.valor,
              valorFinal: aux2.valorFinal,
              descontoConcedido: desconto,
              idTipoDesconto: aux2.idTipoDesconto,
            });
          }
        });
        this.carregando = true;
        service
          .post('Cotacao', 'alteraProdutos', this.form)
          .then((res) => {
            this.carregando = false;
            this.$bvToast.toast('Produtos Alterados com sucesso', {
              title: 'Atenção!',
              solid: true,
              variant: 'success',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          })
          .catch((e) => {
            this.carregando = false;
            this.$bvToast.toast(e, {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          });
      });
    },
    onSubmitAssociado(evt) {
      this.$validator.validateAll('form-1').then((result) => {
        if (!result && !this.formCadastroAssociado.unificaAssociado) {
          this.popToast();
          return;
        }
        if (cpfCnpj.valida_cpf_cnpj(this.formCadastroAssociado.cpf) == false) {
          this.$bvToast.toast('Este CPF ou CNPJ não é válido', {
            title: 'Atenção!',
            solid: true,
            variant: 'danger',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 2500,
          });

          return;
        }
        let objSend = this.dadosProposta;
        objSend.dadosCliente = this.formCadastroAssociado;
        objSend.dadosCliente.nome = this.formCadastroAssociado.associado;
        objSend.dadosCliente.cpfcnpj = this.formCadastroAssociado.cpf;
        this.carregando = true;
        service
          .post('Associado', 'AtualizarAssociadoApp', objSend)
          .then(() => {
            if (this.formCadastroAssociado.unificaAssociado) {
              // this.unificarAssociados();
              window.location.reload();
            }
            this.carregando = false;
            this.$bvToast.toast('Associado Atualizado com sucesso', {
              title: 'Atenção!',
              solid: true,
              variant: 'success',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          })
          .catch((e) => {
            this.carregando = false;
            this.$bvToast.toast(e, {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          });
      });
    },
    buscaProdutosVinculo() {
      this.form.produtosSelecionados.forEach((element) => {
        var aux = this.produtos.find((x) => x.id_produto == element.id);
        if (aux)
          if (aux.produtosLinkados) {
            var prodsLink = aux.produtosLinkados.split(',');
            prodsLink.forEach((element) => {
              var aux2 = this.produtos.find((x) => x.id_produto == element);
              var aux3 = this.form.produtosSelecionados.find(
                (x) => x.id == element,
              );
              if (aux2 && !aux3) {
                this.form.produtosSelecionados.push({
                  id: aux2.id_produto,
                });
              }
            });
          }
      });
    },
    buscaProdutosSobrepoeFaltante() {
      var arrFaltante = [];
      this.produtosObrigatorios.forEach((element) => {
        var aux = this.form.produtosSelecionados.find(
          (x) => x.id == element.id_produto,
        );
        if (!aux) arrFaltante.push(element);
      });
      if (arrFaltante.length > 0) {
        var arrEncontrou = [];
        arrFaltante.forEach((elementFaltante) => {
          this.form.produtosSelecionados.forEach((element) => {
            var aux = this.produtos.find((x) => x.id_produto == element.id);
            if (aux.sobreporOutrosProdutos) {
              var prodssobreporOutrosProdutos =
                aux.sobreporOutrosProdutos.split(',');
              var aux2 = prodssobreporOutrosProdutos.filter(
                (x) => x == elementFaltante.id_produto,
              );
              if (aux2.length > 0) {
                arrEncontrou.push(aux2[0]);
              }
            }
          });
          if (arrEncontrou.length == 0) {
            this.form.produtosSelecionados.push({
              id: elementFaltante.id_produto,
            });
          }
        });
      }
    },
    validaSelecionado(item) {
      var aux = this.form.produtosSelecionados.filter(
        (x) => x.id == item.id_produto,
      );
      if (item.obrigatorio && aux.length > 0) return true;
      else if (item.obrigatorio && aux.length == 0) return false;
      return false;
    },
    selecionaProduto(item) {
      var disabled = this.validaSelecionado(item);

      var isInArr = false;
      this.form.produtosSelecionados.forEach((element, index) => {
        if (element.id == item.id_produto) {
          isInArr = true;
          if (!disabled) this.form.produtosSelecionados.splice(index, 1);
        }
      });
      if (isInArr == false) {
        var aux = this.produtos.find((x) => x.id_produto == item.id_produto);
        this.form.produtosSelecionados.push({
          id: item.id_produto,
        });
      }

      if (item.sobreporOutrosProdutos) {
        var prodssobreporOutrosProdutos =
          item.sobreporOutrosProdutos.split(',');
        prodssobreporOutrosProdutos.forEach((element) => {
          this.form.produtosSelecionados.forEach((elementSel, index) => {
            if (elementSel.id == element) {
              this.form.produtosSelecionados.splice(index, 1);
            }
          });
        });
      } else {
        this.produtos.forEach((element) => {
          if (element.sobreporOutrosProdutos) {
            var prodssobreporOutrosProdutos =
              element.sobreporOutrosProdutos.split(',');
            var aux = prodssobreporOutrosProdutos.find(
              (x) => x == item.id_produto,
            );
            if (aux) {
              this.form.produtosSelecionados.forEach((selecionado, index) => {
                if (selecionado.id == element.id_produto) {
                  this.form.produtosSelecionados.splice(index, 1);
                }
              });
            }
          }
        });
      }
      this.buscaProdutosSobrepoeFaltante();
    },
    buscarCep() {
      if (this.cep.length == 10) {
        this.formCadastroAssociado.cep = this.cep;
        this.carregando = true;
        var cepbusca = this.formCadastroAssociado.cep
          .replace('.', '')
          .replace('-', '');
        service
          .post('Cep', 'BuscaCEP', {
            cep: cepbusca,
          })
          .then((res) => {
            this.encontrouCep = true;
            this.exibeCidade = true;
            this.formCadastroAssociado.endereco = res.data.street;
            this.formCadastroAssociado.bairro = res.data.neighborhood;
            this.formCadastroAssociado.cidade = res.data.city;
            this.formCadastroAssociado.estado = res.data.state;
            this.formCadastroAssociado.pais = 'BR';
            this.carregando = false;
          })
          .catch(() => {
            this.encontrouCep = false;
            this.exibeCidade = false;
            this.carregando = false;
            this.formCadastroAssociado.endereco = this.cliente.endereco;
            this.formCadastroAssociado.bairro = this.cliente.bairro;
            this.formCadastroAssociado.cidade = this.cliente.cidade;
            this.formCadastroAssociado.estado = this.cliente.estado;
            this.formCadastroAssociado.pais = this.cliente.pais;
            this.$bvToast.toast('Não foi possível buscar o CEP', {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          });
      }
    },
    buscaDadosSelects(model, array, campoDescricao, campoPK) {
      this.carregando = true;
      var url = `${model}/list/idEmpresa/${this.form.idEmpresa}`;

      if (model == 'domUf' || model == 'domTipoFipe') url = `${model}/list`;
      service.getAll('g', {}, url).then((res) => {
        res.data = decryptData(res.data);
        this[array].push({ text: 'Selecione', value: null });
        res.data.forEach((element) => {
          this.grupos.push(element);
          this[array].push({
            value: { id: element[campoPK], valor: element[campoDescricao] },
            text: element[campoDescricao],
          });
        });
        this.carregando = false;
      });
    },
    buscaDadosCotacao() {
      this.temaCor = colors.getColorsTema();
      this.carregando = true;
      if (this.$route.params.chave)
        service
          .post('Cotacao', 'listarCotacoes', {
            chave: this.$route.params.chave,
          })
          .then(async (res) => {
            this.dadosProposta = res.data[0];
            this.cliente = this.dadosProposta.dadosCliente;
            this.preencheForm();
            await this.buscaProdutos();
            this.carregando = false;
          });
    },
    formataProdutos(produtos) {
      const _produtos = produtos;

      const produtosObrigatorios = _produtos.filter(
        ({ obrigatorio }) => obrigatorio === true,
      );
      const produtosNaoObrigatorios = _produtos.filter(
        ({ obrigatorio }) => obrigatorio === false,
      );
      const dadosProdutosSelecionados = produtosObrigatorios;

      return {
        produtosObrigatorios,
        produtosNaoObrigatorios,
        dadosProdutosSelecionados,
      };
    },
    validaSelecionadoAgregado(item, id) {
      const aux = this.form.agregados[id].produtosSelecionados.filter(
        (x) => x.id == item.id_produto,
      );

      const obrigatorio = item.obrigatorio && aux.length > 0;
      const naoObrigatorio = item.obrigatorio && aux.length == 0;

      if (obrigatorio) {
        return true;
      }

      if (naoObrigatorio) {
        return false;
      }

      // return false;
    },
    selecionaProdutoAgregado(item, id) {
      var disabled = this.validaSelecionadoAgregado(item, id);

      var isInArr = false;
      this.form.agregados[id].produtosSelecionados.forEach((element, index) => {
        if (element.id == item.id_produto) {
          isInArr = true;
          if (!disabled) {
            this.form.agregados[id].produtosSelecionados.splice(index, 1);
          }
        }
      });

      if (isInArr == false) {
        this.form.agregados[id].produtosSelecionados.push({
          id: item.id_produto,
        });
        this.form.agregados[id].dadosProdutosSelcionados.push(item);
      }

      if (item.sobreporOutrosProdutos) {
        var prodssobreporOutrosProdutos =
          item.sobreporOutrosProdutos.split(',');

        prodssobreporOutrosProdutos.forEach((element) => {
          this.form.agregados[id].produtosSelecionados.forEach(
            (elementSel, index) => {
              if (elementSel.id == element) {
                this.form.agregados[id].produtosSelecionados.splice(index, 1);
                const indexProdSelecionado = this.form.agregados[
                  id
                ].dadosProdutosSelcionados.findIndex(
                  (item) => item.idProduto == elementSel.id,
                );
                this.form.agregados[id].dadosProdutosSelcionados.splice(
                  indexProdSelecionado,
                  1,
                );
              }
            },
          );
        });
      } else {
        this.form.agregados[id].produtos.forEach((element) => {
          if (element.sobreporOutrosProdutos) {
            var prodssobreporOutrosProdutos =
              element.sobreporOutrosProdutos.split(',');
            var aux = prodssobreporOutrosProdutos.find(
              (x) => x == item.id_produto,
            );

            if (aux) {
              this.form.agregados[id].produtosSelecionados.forEach(
                (selecionado, index) => {
                  if (selecionado.id == element.id_produto) {
                    this.form.agregados[id].produtosSelecionados.splice(
                      index,
                      1,
                    );
                    const indexProdSelecionado = this.form.agregados[
                      id
                    ].dadosProdutosSelcionados.findIndex(
                      (item) => item.idProduto == selecionado,
                    );
                    this.form.agregados[id].dadosProdutosSelcionados.splice(
                      indexProdSelecionado,
                      1,
                    );
                  }
                },
              );
            }
          }
        });
      }
      this.buscaProdutosSobrepoeFaltanteAgregado(id);
    },
    buscaProdutosSobrepoeFaltanteAgregado(id) {
      var arrFaltante = [];

      this.form.agregados[id].produtosObrigatorios.forEach((element) => {
        var aux = this.form.agregados[id].produtosSelecionados.find(
          (x) => x.id == element.id_produto,
        );
        if (!aux) arrFaltante.push(element);
      });

      if (arrFaltante.length > 0) {
        var arrEncontrou = [];
        arrFaltante.forEach((elementFaltante) => {
          this.form.agregados[id].produtosSelecionados.forEach((element) => {
            var aux = this.form.agregados[id].produtos.find(
              (x) => x.id_produto == element.id,
            );
            if (aux)
              if (aux.sobreporOutrosProdutos) {
                var prodssobreporOutrosProdutos =
                  aux.sobreporOutrosProdutos.split(',');
                var aux2 = prodssobreporOutrosProdutos.filter(
                  (x) => x == elementFaltante.id_produto,
                );

                if (aux2.length > 0) {
                  arrEncontrou.push(aux2[0]);
                }
              }
          });
          if (arrEncontrou.length == 0) {
            this.form.agregados[id].produtosSelecionados.push({
              id: elementFaltante.id_produto,
            });
          }
        });
      }
    },
    validaCpfUnificacao() {
      this.form.cpfDuplicado = false;
      this.carregando = true;
      this.formVerificaCpf.idEmpresa = this.dadosProposta.idEmpresa;
      this.formVerificaCpf.cpf = this.formCadastroAssociado.cpf;
      service
        .post(
          'Associado',
          'verificaCpfAssociadoDuplicado',
          this.formVerificaCpf,
        )
        .then((res) => {
          if (
            this.dadosProposta.dadosCliente.idProspect !=
              res.data.retorno[0].id_prospect ||
            res.data.retorno.length > 1
          ) {
            if (
              res.data.error == 'CPF Duplicado' &&
              !this.formCadastroAssociado.unificaAssociado
            ) {
              if (!this.showCollapse) {
                this.$root.$emit('bv::toggle::collapse', 'accordion-2');
              }
              this.formCadastroAssociado.cpfDuplicado = true;
            }
            if (
              res.data.error == 'CPF Duplicado' &&
              this.formCadastroAssociado.unificaAssociado == true
            ) {
              this.formCadastroAssociado.cpfDuplicado = false;
            }
          }
          this.carregando = false;
        })
        .catch((e) => {
          this.carregando = false;
          throw new Error(e);
        });
    },
    isCpfCanChange() {
      let retorno = true;
      if (
        this.dadosProposta.dadosCliente.situacao ||
        this.dadosProposta.dadosCliente.idStatus
      ) {
        retorno = false;
      }

      return retorno;
    },
  },
  mounted() {
    this.produtosObrigatorios = this.produtos.filter(
      (x) => x.obrigatorio == true,
    );
    this.produtosNaoObrigatorios = this.produtos.filter(
      (x) => x.obrigatorio != true,
    );
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      this.collapseId = collapseId;
      this.showCollapse = isJustShown;
    });
    this.$validator.localize('en', locale);
    this.buscaDadosSelects('domUf', 'estados', 'nomeUf', 'codUf');
    this.buscaDadosCotacao();
  },
  created: function () {
    this.$watch('form', this.buscaProdutosVinculo, {
      deep: true,
    });
  },
};
</script>

<style lang="scss" scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

.when-closed,
.when-opened {
  float: right;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.card-invisivel {
  border: 0 !important;
}
.atualizarProduto {
  padding-bottom: 50px;
}
.botaoDesconto {
  position: absolute;
  right: 5px;
  top: 2px;
  z-index: 9;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.carregando {
  color: #fff;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  padding-top: 50%;
}

.topoForm {
  border-radius: 10px;
  border-left: 10px solid rgb(52, 138, 167);
  padding: 10px 20px;
  -webkit-box-shadow: 6px 6px 10px -6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 6px 6px 10px -6px rgba(0, 0, 0, 0.28);
  box-shadow: 6px 6px 10px -6px rgba(0, 0, 0, 0.28);
  margin: 10px;
  text-align: center;
  color: rgb(52, 138, 167);
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}

.valorprod {
  color: #000;
  font-weight: bolder;
  font-family: 'roboto';
}
</style>
